var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('router-link',{staticClass:"btn btn-primary btn-sm float-right py-2",attrs:{"to":{ name: 'moderator.discussions.create' }}},[_vm._v("Start New")]),_c('h1',{staticClass:"h3 mb-3 text-gray-800"},[_vm._v("Discussions")])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-link',{staticClass:"float-right mb-4",attrs:{"to":{ name: 'moderator.discussions.closed' }}},[_vm._v("View Closed")]),_c('h2',{staticClass:"h4 mb-3 text-gray-800"},[_vm._v("Open Discussions")]),_c('table',{staticClass:"table table-responsive-md border-bottom"},[_c('tbody',[_vm._l((_vm.topics),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                  name: 'moderator.discussions.show',
                  params: { id: topic.id },
                }}},[_c('img',{class:("avatar " + (_vm.loading ? 'skeleton' : '')),staticStyle:{"height":"40px"},attrs:{"src":topic.author.avatar,"alt":topic.title}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                  name: 'moderator.discussions.show',
                  params: { id: topic.id },
                }}},[_c('h5',{class:("mb-0 " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" ")]),_c('p',{staticClass:"mb-0"},[_c('small',[_vm._v(_vm._s(_vm.$localizer.parse(topic.date)))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                  name: 'moderator.discussions.show',
                  params: { id: topic.id },
                }}},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Replies")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),(_vm.topics.length == 0)?_c('tr',[_vm._m(0)]):_vm._e()],2)])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" There is no data to display ")])])}]

export { render, staticRenderFns }